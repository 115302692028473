import { graphql } from 'gatsby';
import React, { useEffect } from 'react'
import Helmet from '../../components/Helmet';
import TextAndImageBanner from '../../components/Widgets/TextAndImageBanner';
import { getSeoDescription, getSeoKeywords, getSeoTitle, isBrowser } from '../../shared/helpers'

function thankYou(props: any) {

    useEffect(() => {
        if (isBrowser()) {
            window.addEventListener('beforeunload', function (e) {
                e.preventDefault();
                window.history.replaceState('', "false");
            })
        }
    }, [])

    const data = JSON.parse(props.data.allPrismicThankYouContact.edges[0].node.dataString);
    const { body } = data
    
    return (
        <>
            <Helmet
                title={getSeoTitle(data, "Thank you for contacting us")}
                description={getSeoDescription(data, "Contact thank you page")}
                keywords={getSeoKeywords(data, "")} 
            >
                <meta name="robots" content="noindex"/>
            </Helmet>

            {body && body[0] &&
                <TextAndImageBanner data={body[0].primary} />
            }

        </>
    )
}

export const query = graphql`
    query {
        allPrismicThankYouContact {
            edges {
                node {
                    dataString
                }
            }
        }
    }
`;

export default thankYou
